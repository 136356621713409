import AWS from 'aws-sdk'
import { Buffer } from "buffer";

export const replaceFromObject = (ogObj, f) => {
  function replaceStringInObject(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = f(obj[key]);
      } else if (Array.isArray(obj[key])) {
        // If the property is an array, loop through its elements
        for (let i = 0; i < obj[key].length; i++) {
          // Check if the array element is a string before replacing
          if (typeof obj[key][i] === 'string') {
            obj[key][i] = f(obj[key][i]);
          } else if (typeof obj[key][i] === 'object') {
            // If the array element is an object, recursively call the function
            replaceStringInObject(obj[key][i]);
          }
        }
      } else if (typeof obj[key] === 'object') {
        // If the property is an object, recursively call the function
        replaceStringInObject(obj[key]);
      }
    }
  }

  const obj = JSON.parse(JSON.stringify(ogObj))
  replaceStringInObject(obj);
  return obj
}


export const replaceS3 = d => replaceFromObject(
  d,
  str => str.includes('.s3') ? str?.split?.('drivebuddyz')?.join?.('cactus-s3') : str
)

const servers = {
  x: 'https://xrmpxxs3t4.eu-west-3.awsapprunner.com',
  a: 'https://awzip8yzdm.eu-west-3.awsapprunner.com',
  b: 'https://k2b6qugmhv.eu-west-3.awsapprunner.com',
  c: 'https://tehpvhyhdx.eu-west-3.awsapprunner.com',
  '': 'https://xrmpxxs3t4.eu-west-3.awsapprunner.com',
  'static': 'https://xz7.kavanhealth.com',
  'local': 'http://localhost:8080',
}

export const req = async (endpoint, method, body = undefined, server='x') => {
  const baseURL = servers[server]
  const accessToken = localStorage.getItem('accessToken')
  const res = await fetch(`${baseURL}${endpoint}`, { method, headers: { 'Content-Type': "application/json", 'Accept': "application/json", "Authorization": accessToken }, body: JSON.stringify(body) })
  // const res = await fetch(`http://localhost:8080${endpoint}`, { method, headers: { 'Content-Type': "application/json", 'Accept': "application/json", "Authorization": accessToken}, body: JSON.stringify(body) })
  const { status, message, data } = replaceS3(await res.json())
  if (status !== 200) throw message
  return data
}

AWS.config.update({
  accessKeyId: 'AKIAS5LRGSDTKB6TOKCJ',
  secretAccessKey: 'TjzlmnEoUS1ZMJwrnET+u62ya/1FbTbMpPSFt26N',
  region: 'us-east-2'
});

const S3 = new AWS.S3();

export const uploadImageOnS3 = (f) => (src) => {
  // return new Promise((resolve, reject) => {
  try {
    const reader = new FileReader();
    console.log("spoooooooooooooooooorceeee", src)
    reader.onload = async (e) => {
      const params = JSON.parse(JSON.stringify({
        Bucket: 'cactus-s3',
        Key: `${10000 + Math.round(Math.random() * 10000)}.png`,
      }));

      params.Body = new Buffer(reader.result.replace(/^data:image\/\w+;base64,/, ""), 'base64')

      console.log("peeraams", params, reader.result)

      let res = await S3.upload(params).promise()
        .catch(console.error);
      console.log(res);
      // req('/user/resize', 'POST', { image: res.Location })
      return f(res.Location)
    }
    reader.onerror = (e) => console.log("OOPS!", e)
    reader.readAsDataURL(src.target.files[0])
  } catch (error) {
    console.error('Error uploading to S3:', error);
    // reject(error)
  }
  // })
}