import axios from 'axios'
import axiosInstance from './axiosInstance'
import { GenericService } from './GenericService'

class UserService extends GenericService {
    constructor() {
        super('/api/auth')
    }

    loginUser = (email, password, role) =>
        this.post('/user/login', {
            email,
            password,
            // role: 'admin',
        })
    loginWithToken = async (accessToken) => {
        console.log(accessToken)
        if(!accessToken) return {
            data: {
                name: 'a',
                email: 'a@b.com',
                _id: '123',
            }
        }
        // return fetch('https://xz6.kavanhealth.com/user/me', { method: 'GET', headers: { 'Content-Type': "application/json", 'Authorization': accessToken } }).
        //     then(data => data.json())
    }
    addRec = (data) => this.post('/api/user/adminAddUser', data)
    editRec = (data) => this.post('/api/user/update', data)
    getSingleRecord = (data) => this.post('/api/user/getSingle', data)
}

export default new UserService()
